























import { Component, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'

@Component({
  components: {
    SidebarSwitcher,
    ButtonBase,
    SelectBase,
  },
})
export default class SelectPrefecture extends Vue {
  private selectedPrefecture: number | null = null

  private prefectureOptions = []

  private async mounted() {
    Vue.prototype.$loading.start()
    await this.loadPrefectureOptions()
    await this.loadStudentPrefecture()
    Vue.prototype.$loading.complete()
  }

  /**
   * 教科選択ボタン表示タイプ
   * 都道府県未選択時は非活性
   */
  private get colorTypeSelectSubject() {
    return this.selectedPrefecture ? 'gradation-orange' : 'pointer-events-none'
  }

  /**
   * 都道府県一覧取得
   */
  private async loadPrefectureOptions() {
    const result = await Vue.prototype.$http.httpWithToken.get('/prefectures')

    this.prefectureOptions = result.data.map((prefecture: any) => {
      return { text: prefecture.name, value: prefecture.id }
    })
  }

  /**
   * 過去に登録済みの都道府県取得
   */
  private async loadStudentPrefecture() {
    const result = await Vue.prototype.$http.httpWithToken.get('/entrance/student_prefectures')
    this.selectedPrefecture = result.data?.prefectureId || null
  }

  /**
   * 選択した都道府県を登録
   */
  private async setStudentPrefecture() {
    const params = { prefecture_id: this.selectedPrefecture }
    await Vue.prototype.$http.httpWithToken.post('/entrance/student_prefectures', params)
  }

  /**
   * 都道府県登録 & 教科選択画面へ遷移
   */
  private async onClickSelectSubject() {
    try {
      await this.setStudentPrefecture()
    } catch {
      return alert('都道府県登録に失敗しました。')
    }

    this.$router.push('/student/subjects')
  }
}
